<template>
  <b-container fluid class="balance-report-table-networth-wrapper px-0">

    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t(translationPath + 'title_networth' )}}</h2>
          </template>

          <div class="col-lg-10 pl-0 text-regular" v-html="$t(translationPath + 'description_networth', {
            opening_balance_date: this.opening_balance_date,
            period_start_date: this.period_start_date,
            period_end_date: this.period_end_date,
            closing_balance_date: this.closing_balance_date
            })"></div>

          <b-table class="spirecta-simple-table balance-report-table-networth" show-empty hover responsive striped
                   :items="items"
                   :fields="tableFields"
                   stacked="md"
          >
            <!--loader-->
            <template v-slot:table-busy>
              <loader/>
            </template>
            <!--loader ends here-->

            <template v-slot:head(opening_balance)="item">
              {{item.label}}<br />
              <small>{{ opening_balance_date }}</small>
            </template>

            <template v-slot:cell(type)="row">
              <template v-if="row.item.reference_type === 'asset'">
                <b-link class="linkButton" href="javascript:void(0);" @click.prevent="emitTabChange('asset')"> {{ row.value }} </b-link>
              </template>
              <template v-else-if="row.item.reference_type === 'liability'">
                <b-link class="linkButton" href="javascript:void(0);" @click.prevent="emitTabChange('liability')"> {{ row.value }} </b-link>
              </template>
              <template v-else>
                {{ row.value }}
              </template>
            </template>
            <template v-slot:head(closing_balance)="item">
              {{item.label}}<br />
              <small>{{ closing_balance_date }}</small>
            </template>

            <template v-slot:head(period_change)="item">
              {{item.label}}<br />
              <small>{{ period_start_date }} - {{ period_end_date }}</small>
            </template>
          </b-table>

        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import formatAmount from '@/assets/filters/formatAmount'
import defaultValueAssigner from '@/mixins/defaultValueAssigner'

export default {
  props: {
    translationPath: null,
    apiData: null,
    busyState: null,
    currentCOA: null
  },
  name: 'BalanceReportTableNetworth',
  mixins: [defaultValueAssigner],
  data () {
    return {
      items: [],
      opening_balance_date: null,
      period_start_date: null,
      period_end_date: null,
      closing_balance_date: null
    }
  },
  computed: {
    tableFields () {
      return [
        { key: 'type', label: '' },
        { key: 'opening_balance', label: this.$t(this.translationPath + 'table.opening_balance'), class: 'text-left text-md-right', formatter: 'amountFormatter' },
        { key: 'period_change', label: this.$t(this.translationPath + 'table.period_change'), class: 'text-left text-md-right', formatter: 'amountFormatter' },
        { key: 'closing_balance', label: this.$t(this.translationPath + 'table.closing_balance'), class: 'text-left text-md-right', formatter: 'amountFormatter' }
      ]
    }
  },
  created () {
    this.loadTableData()
  },
  methods: {
    emitTabChange (value) {
      this.$emit('change-tab', value)
    },
    amountFormatter (value) {
      return this.currentCOA && this.currentCOA.locale && this.currentCOA.currency && this.currentCOA.currency_iso ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : value
    },
    loadTableData () {
      if (this.busyState === false) {
        this.items = []
        if (!this.apiData || Array.isArray(this.apiData) || Object.keys(this.apiData).length === 0) {
          return false
        }
        this.opening_balance_date = this.defaultValueAssigner(this.apiData.dates.opening_balance)
        this.closing_balance_date = this.defaultValueAssigner(this.apiData.dates.closing_balance)
        this.period_start_date = this.defaultValueAssigner(this.apiData.dates.period_start_date)
        this.period_end_date = this.defaultValueAssigner(this.apiData.dates.period_end_date)

        // Assets
        this.items.push({
          type: this.$t(this.translationPath + 'table.total_assets'),
          opening_balance: this.defaultValueAssigner(this.apiData.totals.sums.asset.opening_balance),
          period_change: this.defaultValueAssigner(this.apiData.totals.sums.asset.period_change),
          closing_balance: this.defaultValueAssigner(this.apiData.totals.sums.asset.closing_balance),
          reference_type: 'asset'
        })

        // Liabilities
        this.items.push({
          type: this.$t(this.translationPath + 'table.total_liabilities'),
          opening_balance: this.defaultValueAssigner(this.apiData.totals.sums.liability.opening_balance),
          period_change: this.defaultValueAssigner(this.apiData.totals.sums.liability.period_change),
          closing_balance: this.defaultValueAssigner(this.apiData.totals.sums.liability.closing_balance),
          reference_type: 'liability'
        })

        // Networth
        this.items.push({
          type: this.$t(this.translationPath + 'table.total_networth'),
          opening_balance: this.defaultValueAssigner(this.apiData.totals.sums.networth.opening_balance),
          period_change: this.defaultValueAssigner(this.apiData.totals.sums.networth.period_change),
          closing_balance: this.defaultValueAssigner(this.apiData.totals.sums.networth.closing_balance),
          reference_type: 'networth'
        })
      }
    }
  },
  watch: {
    apiData: {
      handler () {
        this.loadTableData()
      }
    },
    busyState: {
      handler () {
        this.loadTableData()
      }
    }
  }
}
</script>

<style type="text/css" lang="scss">
.balance-report-table-networth {

  tr:nth-child(3) {
    border-top:1px solid black;
    border-bottom:2px solid black;
    font-weight: 800;
    }
  .linkButton {
    background: transparent;
    color: #212529;
    border: none;
    padding: 0 !important;
    font-weight: 700;
    &:hover {
      background: transparent;
      color: #212529;
    }
    &:focus {
      background: transparent;
      color: #212529;
      box-shadow: none;
    }
    &:active {
      background-color: transparent !important;
      color: #212529 !important;
    }
  }
}
</style>
