<template>
  <b-container fluid class="balance-report-chart-change-distribution-chart-wrapper">
    <template v-if="apiData && !Array.isArray(this.apiData) && Object.keys(apiData).length > 0">
      <bar-chart
        :chart-data="chartData"
        :options="chartOptions"
        :chart-id="'balance-report-chart-change-distribution-chart'"
        :width="100"
        :height="400"
        :css-classes="''"
        :plugins="[]"
      >
      </bar-chart>
    </template>
    <template v-else>
      <empty-graph :message="$t('incomes_expenses.accounts.view.no_transaction_no_chart')"/>
    </template>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import EmptyGraph from '@/components/common/EmptyGraph'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
// import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceReportChartChangeDistribution',
  props: [
    'apiData',
    'translationPath',
    'currencySymbol'
  ],
  mixins: [chartOptions],
  components: {
    BarChart,
    EmptyGraph
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: []
      }
    }
  },
  mounted () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      if (!this.apiData || this.apiData == null || Array.isArray(this.apiData) || Object.keys(this.apiData).length === 0) {
        return 0
      }

      const cangeLabel = this.$t(this.translationPath + 'change')
      const balanceInLabel = this.$t(this.translationPath + 'balance_in')
      const balanceOutLabel = this.$t(this.translationPath + 'balance_out')
      const suffix = ' ' + this.currencySymbol

      this.chartOptions.legend.display = false
      /* Modify Y-mouse over */
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        let lblRowBalanceIn = ''
        let lblRowBalanceOut = ''
        let lblRowBalanceChange = ''
        let aTooltip = []

        // Offset makes yLabel a string that looks like an array
        if (tooltipItem.yLabel[0] === '[') {
          const tmpArray = JSON.parse(tooltipItem.yLabel)

          lblRowBalanceIn = balanceInLabel + ': ' + formatNumberToFull(tmpArray[1]) + suffix
          lblRowBalanceOut = balanceOutLabel + ': ' + formatNumberToFull(tmpArray[0]) + suffix
          lblRowBalanceChange = cangeLabel + ': ' + formatNumberToFull(tmpArray[0] - tmpArray[1]) + suffix

          aTooltip = [lblRowBalanceIn, lblRowBalanceChange, lblRowBalanceOut]
        } else {
          aTooltip = [balanceInLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix]
        }

        return aTooltip
      }
      const labelData = []
      const transactionData = []
      const colorData = []
      const translationPath = 'reports._common.distribution.'

      // Incoming balance
      transactionData.push(Math.round(this.apiData.totals.sums.networth.opening_balance))
      labelData.push([this.$t(translationPath + 'incoming_balance_abbr'), this.apiData.dates.opening_balance])
      colorData.push(Math.round(this.apiData.totals.sums.networth.opening_balance) <= 0 ? '#dda3a2' : '#8cccad')

      // Calc offset
      var offset = Math.round(this.apiData.totals.sums.networth.opening_balance)
      console.log(this.apiData.distribution.totals.networth)
      for (var index in this.apiData.distribution.totals.networth) {
        var iSum = Math.round(this.apiData.distribution.totals.networth[index].sum)
        transactionData.push([iSum + offset, offset])
        offset += iSum

        colorData.push(iSum <= 0 ? '#dda3a2' : '#8cccad')
        labelData.push(this.$t(translationPath + 'tbl_transactions.' + index + '_abbr'))
      }

      transactionData.push(Math.round(this.apiData.totals.sums.networth.closing_balance))
      labelData.push([this.$t(translationPath + 'outgoing_balance_abbr'), this.apiData.dates.closing_balance])
      colorData.push(Math.round(this.apiData.totals.sums.networth.closing_balance) <= 0 ? '#dda3a2' : '#8cccad')

      // Recalculate y-axis min
      // let iBalanceChange = Math.round(this.apiData.totals.sums.networth.closing_balance - this.apiData.totals.sums.networth.opening_balance)
      // iBalanceChange = Math.round(this.apiData.totals.sums.networth.opening_balance - iBalanceChange)
      // this.chartOptions.scales.yAxes[0].ticks.min = iBalanceChange >= 0 ? (Math.round(iBalanceChange * 0.9 / 10000) * 10000).toFixed(0) : 0

      console.log(this.apiData.distribution.chart_meta.min_tick)
      this.chartOptions.scales.yAxes[0].ticks.min = this.apiData.distribution.chart_meta.min_tick

      const dataTransactionValues = {
        label: this.$t('common.amount'),
        data: transactionData,
        borderColor: '#8cccad',
        backgroundColor: colorData,
        hoverBackgroundColor: '#ACACAC'
      }

      this.chartData = {
        labels: labelData,
        datasets: [dataTransactionValues]
      }
    }
  }
}
</script>

<style lang="scss">
  .balance-report-chart-change-distribution-chart-wrapper{

  }
</style>
