<template>
  <b-container fluid class="balance-report-chart-time-weighted-return-chart-wrapper">
    <template v-if="apiData && !Array.isArray(this.apiData) && Object.keys(apiData).length > 0">
      <bar-chart
        :chart-data="chartData"
        :options="chartOptions"
        :chart-id="'balance-report-chart-time-weighted-return-chart'"
        :width="100"
        :height="400"
        :css-classes="''"
        :plugins="[]"
      >

      </bar-chart>
    </template>
    <template v-else>
      <empty-graph :message="$t('incomes_expenses.accounts.view.no_transaction_no_chart')"/>
    </template>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import EmptyGraph from '@/components/common/EmptyGraph'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceReportChartTimeWeightedReturn',
  props: [
    'apiData',
    'translationPath'
  ],
  mixins: [chartOptions],
  components: {
    BarChart,
    EmptyGraph
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: []
      }
    }
  },
  mounted () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      if (!this.apiData || this.apiData == null || Array.isArray(this.apiData) || Object.keys(this.apiData).length === 0) {
        return 0
      }

      const sReturnLabel = this.$t('reports.balance.balance_report2.tick_label_twr_return')

      this.chartOptions.legend = {
        display: false
      }

      this.chartOptions.scales = {
        xAxes: [{
          display: true,
          type: 'time'
        }],
        yAxes: [{
          display: true,
          gridLines: { display: false },
          position: 'left',
          ticks: {
            fontColor: '#231f20',
            beginAtZero: true,
            fontFamily: 'Nunito',
            callback: function (value, index, values) {
              return formatNumberToFull(value) + ' %'
            }
          }
        }]
      }

      /* Modify Y-mouse over */
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toFixed(1) + ' %'
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return sReturnLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + ' %'
      }

      this.chartOptions.tooltips.callbacks.title = function (tooltipItem) {
        const dateObject = new Date(tooltipItem[0].xLabel)
        const dateString = dateObject.getFullYear() + '-' + (dateObject.getMonth() < 9 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)) + '-' + (dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate())

        return dateString
      }

      const labelData = []
      const aReturnRateData = []

      for (var index in this.apiData.time_weighted_return.steps) {
        aReturnRateData.push((this.apiData.time_weighted_return.steps[index].return_rn_acc * 100).toFixed(2))
        labelData.push(new Date(this.apiData.time_weighted_return.steps[index].date))
      }

      const aReturnRateDataset = {
        label: this.$t('common.percentage'),
        data: aReturnRateData,
        borderColor: '#8cccad',
        type: 'line',
        steppedLine: 'middle',
        fill: true
      }

      this.chartData = {
        labels: labelData,
        datasets: [aReturnRateDataset]
      }
    }
  }
}
</script>

<style lang="scss">
  .balance-report-chart-time-weighted-return-chart-wrapper{

  }
</style>
