<template>
  <b-container fluid class="balance-report-table-change-distribution-wrapper px-0 mb-4">

    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t(translationPath + 'title_change_distribution' )}}</h2>
          </template>

          <div class="col-lg-10 pl-0 text-regular" v-html="$t(translationPath + 'description_change_distribution', {period_start: this.periodStart, period_end: this.periodEnd})"></div>

          <b-table class="spirecta-simple-table balance-report-table-assets" show-empty hover responsive striped
                   :items="networthChangeTransactionTypeTableData"
                   :fields="tableFields"
                   stacked="md"
          >
            <!-- TITLE -->
            <template v-slot:cell(transaction_type)="row">
              <template v-if="row.item.meta_row_type === 'transaction_type'">
                <b-link :to="'/transactions/view/?transaction_subtype='+row.item.transaction_subtype+'&start_date='+periodStart+'&end_date='+periodEnd">{{row.item.transaction_type}}</b-link>
              </template>
              <template v-else>
                {{row.item.transaction_type}}
              </template>
            </template>

            <!-- SUM -->
            <template v-slot:cell(amount)="row">
              <template v-if="row.item.meta_row_type === 'transaction_type'">
                <b-link :to="'/transactions/view/?transaction_subtype='+row.item.transaction_subtype+'&start_date='+periodStart+'&end_date='+periodEnd">{{amountFormatter(row.item.amount)}}</b-link>
              </template>
              <template v-else>
                {{amountFormatter(row.item.amount)}}
              </template>
            </template>

          </b-table>

        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import formatAmount from '@/assets/filters/formatAmount'

export default {
  props: {
    translationPath: null,
    apiData: null,
    busyState: null,
    currentCOA: null,
    periodStart: null,
    periodEnd: null
  },
  name: 'BalanceReportTableChangeDistribution',
  data () {
    return {
      networthChangeTransactionTypeTableData: [],
      opening_balance_date: null,
      closing_balance_date: null
    }
  },
  computed: {
    tableFields () {
      return [
        { key: 'transaction_type', label: this.$t(this.translationPath + 'table.title_change_distribition_transaction_types') },
        { key: 'amount', label: this.$t(this.translationPath + 'table.amount'), class: 'text-left text-md-right', formatter: 'amountFormatter' }
      ]
    }
  },
  created () {
    this.loadTableData()
  },
  methods: {
    amountFormatter (value) {
      return this.currentCOA && this.currentCOA.locale && this.currentCOA.currency && this.currentCOA.currency_iso ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : value
    },
    loadTableData () {
      if (this.busyState === false) {
        this.networthChangeTransactionTypeTableData = []
        if (!this.apiData || Array.isArray(this.apiData) || Object.keys(this.apiData).length === 0) {
          return false
        }

        let iSum = 0
        for (var index in this.apiData.distribution.totals.networth) {
          this.networthChangeTransactionTypeTableData.push({
            transaction_type: this.$t('reports._common.distribution.tbl_transactions.' + index),
            transaction_subtype: index,
            meta_row_type: 'transaction_type',
            amount: Math.round(this.apiData.distribution.totals.networth[index].sum)
          })
          iSum += Math.round(this.apiData.distribution.totals.networth[index].sum)
        }
        this.networthChangeTransactionTypeTableData.push({
          transaction_type: this.$t('reports._common.distribution.tbl_transactions.sum_change_distribution'),
          meta_row_type: 'total_sum',
          amount: iSum
        })
      }
    }
  },
  watch: {
    apiData: {
      handler () {
        this.loadTableData()
      }
    },
    busyState: {
      handler () {
        this.loadTableData()
      }
    }
  }
}
</script>

<style type="text/css" lang="scss">
.balance-report-table-change-distribution-wrapper{
  .balance-report-table-assets thead th:first-child{
    font-size:16pt;
  }
}
</style>
