<template>
    <b-container fluid class="balance-report-over-time-table-wrapper balance-report-over-time-total-table-wrapper px-0 mt-3">
        <b-row no-gutters>
            <b-col>
                <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
                    <template v-slot:header>
                        <h2 class="mb-3">{{ $t('common.header.header_menu.user_menu.settings') }}</h2>
                    </template>
                    <template v-if="allSettings.length > 0">
                      <h3>{{ $t('common.save_as_report') }}</h3>
                      <p>{{ $t('common.save_as_report_description') }}</p>
                    </template>
                    <!--Custom Settings-->
                    <CustomTabFilter
                        disableOk
                        :disableOkayButtonUnderFields="false"
                        ref="customTab"
                        :sections="sections"
                        :saveLabel="$t('common.save')"
                        :okayLabel="$t('common.okay_report_settings')"
                        :settingsData="allSettings"
                        :busyState="busySettingsState"
                        @select="(value) => handleSelectSetting(value.id, value.content)"
                        @submit="handleActiveTabs"
                        @okay="(value) => handleSelectSetting(null, value)"
                        @delete="handleDelete">
                        <!--HEADING UNDER TABLE-->
                        <template #details>
                            <h3>{{ $t('common.save_settings_as_report') }}</h3>
                        </template>
                        <!--CUSTOM SLOT-->
                        <template #savedetails>
                            <h4>{{ $t('common.save_settings_as_report_2') }}</h4>
                            <p>{{ $t('common.save_settings_as_report_description_2') }}</p>
                        </template>
                        <!--CUSTOM SLOT END HERE-->
                    </CustomTabFilter>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import CustomTabFilter from '@/components/common/CustomTabFilter'
import TabSettingsCommonMixins from '@/mixins/TabSettingsCommon'

export default {
  name: 'BalanceReportOverTimeSettings',
  mixins: [TabSettingsCommonMixins],
  components: { CustomTabFilter },
  props: {
    sections: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  mounted () {
    this.initializing()
  },
  methods: {
    handleSelectSetting (id, value) {
      if (id) {
        this.preSelectId = id
      }
      this.$refs.customTab.reset()
      const valueKeys = Object.keys(value)
      const sections = [...this.sections].map((el) => {
        if (valueKeys.indexOf(el.model) > -1) {
          let values = value[el.model]
          if (id === null && ['title', 'description'].indexOf(el.model) > -1) {
            values = null
          }
          return Object.assign({}, el, {
            value: values
          })
        }
        return el
      })
      /*
      * Emit Settings Update
      */
      this.$emit('settings-updated', sections)
    },
    afterTabSettingsUpdate () {
      // Show success message
      this.$refs.customTab.showSuccess()
      // Reset all the values
      this.$refs.customTab.reset()
    }
  }
}
</script>
