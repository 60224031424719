<script>
/*
* Custom Tab Filter
* @purpose Settings tabs for filtering the reports
*
*/
import { required } from 'vuelidate/lib/validators'
import Loader from '@/components/common/Loader'
import DeleteModal from '@/components/modals/DeleteModal'

export default {
  name: 'CustomTabFilter',
  components: { Loader, DeleteModal },
  props: {
    /*
    * Fields for the settings
    * @input array({
    *   label: Label for the field,
    *   description: Description below label,
    *   model: Model name of the field,
    *   value: Value of the field,
    *   child: [{
    *     type: type of field (input, select, checkbox),
    *     multiple: Mulitple option for select,
    *     options: Options array for select
    *   }]
    * })
    */
    sections: {
      type: Array,
      default: function () {
        return []
      }
    },
    /*
    * Label for save button
    * @type string
    */
    saveLabel: {
      type: String,
      default: 'Save'
    },
    /*
    * Disable save button
    * @type Boolean
    * @values true or false
    * @default false
    */
    disableSave: {
      type: Boolean,
      default: false
    },
    /*
    * Label for okay button
    * @type string
    */
    okayLabel: {
      type: String,
      default: 'Ok'
    },
    /*
    * Disable okay button
    * @type Boolean
    * @values true or false
    * @default false
    */
    disableOk: {
      type: Boolean,
      default: false
    },
    /*
    * Success message
    * @type string
    */
    successMessage: {
      type: String,
      default: 'common.settings_save_success'
    },
    /*
    * Data for settings table
    * @type array
    * @default []
    */
    settingsData: {
      type: Array,
      default: function () {
        return []
      }
    },
    /*
    * Busy state for settings table
    * @type Boolean
    * @values true or false
    * @default false
    */
    busyState: {
      type: Boolean,
      default: false
    },
    /*
    * Show settings table
    * @type Boolean
    * @values true or false
    * @default true
    */
    listTable: {
      type: Boolean,
      default: true
    },
    disableOkayButtonUnderFields: {
      type: Boolean,
      default: true
    },
    positionCustomOkayUnderFields: {
      type: Number,
      default: 3
    },
    positionCustomSlot: {
      type: Number,
      default: 3
    },
    /*
    * Delete confirm modal
    */
    deleteConfirmation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMessage: false,
      optionvalues: {},
      deleteId: null
    }
  },
  validations () {
    let validationProperty = {
      optionvalues: {}
    }
    this.sections.map((el) => {
      const property = {}
      property[`${el.model}`] = el.required ? { required } : {}
      const optionValidations = Object.assign({}, validationProperty.optionvalues, property)
      validationProperty = Object.assign({}, validationProperty, { optionvalues: optionValidations })
    })
    return validationProperty
  },
  render: function (createElement) {
    // render element
    const self = this
    const propertyList = []
    const horizontalRow = createElement('hr')
    const childElement = this.sections.map((el, index) => {
      const childrens = el.child || []
      const propertyName = el.model.toString().split(' ').join('').toLowerCase()
      propertyList.push(propertyName)
      const childrensElements = []
      const okayButtonBelowFields = []
      /*
      * Okay button below fields
      */
      if (!this.disableOkayButtonUnderFields && index === this.positionCustomOkayUnderFields - 1) {
        const okayButton = createElement('b-button', {
          props: {
            variant: 'primary'
          },
          class: {
            'mr-2': true
          },
          domProps: {
            innerHTML: this.okayLabel
          },
          on: {
            click: function () {
              self.$v.$reset()
              const dataToEmit = propertyList.map((el) => {
                const property = {}
                property[el] = self.optionvalues[el]
                return property
              }).reduce((el, acc) => Object.assign({}, el, acc))
              /*
              * Emit okay event
              */
              self.$emit('okay', dataToEmit)
            }
          }
        })
        const okayButtonRow = createElement('b-col', { props: { cols: 12, sm: 8, md: 8 }, class: { 'px-0': true, 'mb-2': !self.$slots.savedetails, 'mb-3': self.$slots.savedetails } }, [okayButton])
        okayButtonBelowFields.push(okayButtonRow)
      }
      /*
      * Template slot for details (v-slot:savedetails)
      */
      if (index === this.positionCustomSlot - 1 && self.$slots.savedetails) {
        const okayButtonRow = createElement('b-col', { props: { cols: 12, sm: 8, md: 8 }, class: { 'px-0': true, 'mb-3': true } }, [self.$slots.savedetails])
        okayButtonBelowFields.push(horizontalRow)
        okayButtonBelowFields.push(okayButtonRow)
      }
      childrens.map((elchild) => {
        const props = {}
        if (elchild.type === 'checkbox') {
          Object.assign(props, {
            props: {
              value: self.optionvalues[propertyName]
            },
            scopedSlots: {
              default: () => elchild.text
            },
            on: {
              change: function (value) {
                const newproperty = []
                newproperty[propertyName] = value
                self.optionvalues = Object.assign({}, self.optionvalues, newproperty)
              }
            }
          })
        } else if (elchild.type === 'select') {
          let optionSize = elchild.options.length || 2
          elchild.options.map(option => {
            if (Object.prototype.hasOwnProperty.call(option, 'options')) {
              optionSize += option.options.length
            }
          })
          if (optionSize > 10 && optionSize <= 20) {
            optionSize = 10
          } else if (optionSize > 20) {
            optionSize = Math.round(optionSize / 2)
          }
          Object.assign(props, {
            props: {
              options: elchild.options || [],
              multiple: elchild.multiple || false,
              value: self.optionvalues[propertyName],
              selectSize: optionSize
            },
            ref: propertyName,
            on: {
              change: function (value) {
                const newproperty = []
                newproperty[propertyName] = value
                self.optionvalues = Object.assign({}, self.optionvalues, newproperty)
              }
            }
          })
        } else if (elchild.type === 'input' || elchild.type === 'textarea') {
          Object.assign(props, {
            props: {
              value: self.optionvalues[propertyName]
            },
            on: {
              input: function (value) {
                const newproperty = []
                newproperty[propertyName] = value.length > 0 ? value : null
                self.optionvalues = Object.assign({}, self.optionvalues, newproperty)
                self.$v.optionvalues[propertyName].$touch()
              }
            }
          })
        }
        childrensElements.push(createElement(`b-form-${elchild.type}`, props))
      })
      const fieldRequired = el.required || false
      if (fieldRequired) {
        const displayError = createElement('b-alert', {
          class: {
            settingsTabsError: true
          },
          props: {
            show: self.$v.optionvalues[el.model].$anyDirty && self.$v.optionvalues[el.model].$anyError,
            variant: 'danger'
          },
          scopedSlots: {
            default: () => self.$t('common.errors.required')
          }
        })
        childrensElements.push(displayError)
      }
      const lableRowChild = []
      const labelComponentElement = createElement('label', {
        attrs: {
          for: el.model
        },
        domProps: {
          innerHTML: this.$te(el.label) ? this.$t(el.label) : el.label
        },
        class: {
          'mb-0': true,
          'font-weight-bold': true
        }
      })
      lableRowChild.push(labelComponentElement)
      // Description under label
      if (Object.prototype.hasOwnProperty.call(el, 'description') && el.description) {
        lableRowChild.push(createElement('p', {
          domProps: {
            innerHTML: this.$te(el.description) ? this.$t(el.description) : el.description
          },
          class: {
            'mb-0': true
          }
        }))
      }
      const labelComponent = createElement('b-col', {}, lableRowChild)
      const disableButtons = el.disableButtons || false
      let selectButtons = []
      if (!disableButtons) {
        selectButtons = createElement('b-col', { class: { 'px-0': true }, props: { cols: '12', sm: 4 } }, [
          createElement('b-button', {
            domProps: {
              innerHTML: this.$t('common.select_none')
            },
            class: {
              'pull-right': true
            },
            on: {
              click: function () {
                const newproperty = []
                newproperty[propertyName] = []
                self.optionvalues = Object.assign({}, self.optionvalues, newproperty)
              }
            }
          }),
          createElement('b-button', {
            domProps: {
              innerHTML: this.$t('common.select_all')
            },
            class: {
              'mr-2': true,
              'pull-right': true
            },
            on: {
              click: function () {
                const allValue = Object.keys(self.$refs[propertyName].$el).filter((elem) => self.$refs[propertyName].$el[elem].nodeName === 'OPTION').map((elem) => parseInt(self.$refs[propertyName].$el[elem].value))
                const newproperty = []
                newproperty[propertyName] = allValue
                self.optionvalues = Object.assign({}, self.optionvalues, newproperty)
              }
            }
          })
        ])
      }
      const actionButtons = createElement('b-row', { class: { 'px-3': true, 'mb-2': true, mulitselectAction: true }, props: { alignV: 'center' } }, [labelComponent, selectButtons])
      const sectionsField = createElement('b-form-group', {
        props: {
          labelClass: 'align-self-center pr-md-0 pr-lg-4 text-black'
        }
      }, [...okayButtonBelowFields, actionButtons, ...childrensElements])
      const sectionFieldElement = [
        createElement('b-col', {
          props: {
            cols: 12
          },
          class: {
            'px-0': true
          }
        }, [sectionsField])
      ]
      return sectionFieldElement
    })
    const actionButtons = []
    if (!this.disableOk) {
      /*
      * Okay button
      */
      const okayButton = createElement('b-button', {
        props: {
          variant: 'primary'
        },
        class: {
          'mr-2': true
        },
        domProps: {
          innerHTML: this.okayLabel
        },
        on: {
          click: function () {
            self.$v.$reset()
            const dataToEmit = propertyList.map((el) => {
              const property = {}
              property[el] = self.optionvalues[el]
              return property
            }).reduce((el, acc) => Object.assign({}, el, acc))
            /*
            * Emit okay event
            */
            self.$emit('okay', dataToEmit)
          }
        }
      })
      actionButtons.push(okayButton)
    }
    if (!this.disableSave) {
      /*
      * Save button
      */
      const saveButton = createElement('b-button', {
        props: {
          variant: 'secondary'
        },
        domProps: {
          innerHTML: this.saveLabel
        },
        on: {
          click: function () {
            self.$v.$touch()
            if (self.$v.$invalid) {
              return false
            }
            const dataToEmit = propertyList.map((el) => {
              const property = {}
              property[el] = self.optionvalues[el]
              return property
            }).reduce((el, acc) => Object.assign({}, el, acc))
            /*
            * Emit submit event
            */
            self.$emit('submit', dataToEmit)
          }
        }
      })
      actionButtons.push(saveButton)
    }
    const childElements = [...childElement, ...actionButtons]
    const defaultComponents = []
    /*
    * Success message
    */
    const alertBox = createElement('b-alert', {
      props: {
        variant: 'success',
        show: self.showMessage,
        dismissible: true,
        fade: true
      },
      scopedSlots: {
        default: () => self.$te(self.successMessage) ? self.$t(self.successMessage) : self.successMessage
      }
    })
    defaultComponents.push(alertBox)
    if (this.listTable) {
      /*
      * Listing table
      */
      const listingComponent = createElement('b-table', {
        props: {
          items: this.settingsData,
          showEmpty: true,
          fields: this.fieldValues,
          hover: true,
          responsive: true,
          striped: true,
          stacked: 'sm',
          busy: this.busyState,
          emptyText: this.$t('common.no_setting_found')
        },
        class: {
          'spirecta-simple-table': true,
          'settings-table': true,
          'mb-4': true
        },
        scopedSlots: {
          'table-busy': () => createElement('loader'),
          'cell(action)': (row) => createElement('div', {}, [createElement('b-button', {
            props: {
              variant: 'primary'
            },
            class: {
              'mr-2': true
            },
            on: {
              click: function () {
                /*
                * Emit select event
                */
                self.$emit('select', {
                  id: row.item.id,
                  content: row.item.content
                })
              }
            },
            domProps: {
              innerHTML: this.$t('common.select')
            }
          }),
          createElement('b-button', {
            props: {
              variant: 'secondary'
            },
            on: {
              click: function () {
                if (self.deleteConfirmation) {
                  // Show delete modal if enabled
                  self.deleteId = row.item.id
                  self.$refs.CustomTabDeleteModal.show()
                } else {
                  /*
                  * Emit select event
                  */
                  self.$emit('delete', row.item.id)
                }
              }
            },
            domProps: {
              innerHTML: this.$t('common.delete')
            }
          })])
        }
      })
      if (this.settingsData.length > 0) {
        defaultComponents.push(listingComponent)
        defaultComponents.push(horizontalRow)
      }
    }
    /*
    * Confirm Modal
    */
    if (this.listTable && this.deleteConfirmation) {
      const deleteConfirmModal = createElement('delete-modal', {
        ref: 'CustomTabDeleteModal',
        props: {
          message: self.$t('common.confirm_delete_settings')
        },
        on: {
          'on-delete-cancel': function () {
            self.deleteId = null
          },
          'on-delete-confirm': function () {
            self.$emit('delete', self.deleteId)
            self.$refs.CustomTabDeleteModal.hide()
            self.deleteId = null
          }
        }
      })
      defaultComponents.push(deleteConfirmModal)
    }
    /*
    * Template slot for details (v-slot:deatils)
    * Slot ablove fields
    */
    if (self.$slots.details) {
      const underTableRow = createElement('b-col', { props: { cols: 12, sm: 8, md: 8 }, class: { 'px-0': true, 'mb-3': true } }, [self.$slots.details])
      defaultComponents.push(underTableRow)
    }
    const parentElement = createElement('div', { class: { customFilter: true } }, [...defaultComponents, ...childElements])
    return parentElement
  },
  methods: {
    /*
    * Show success message
    */
    showSuccess () {
      this.showMessage = true
      setTimeout(function () {
        this.showMessage = false
      }.bind(this), 3000)
    },
    /*
    * Reset all the field values
    */
    reset () {
      Object.keys(this.optionvalues).map((el) => {
        let initial = null
        if (!this.optionvalues[el]) {
          initial = null
        } else if (this.optionvalues[el] === '') {
          initial = ''
        } else if (typeof this.optionvalues[el] === 'object' && Array.isArray(this.optionvalues[el])) {
          initial = []
        } else if (typeof this.optionvalues[el] === 'object' && !Array.isArray(this.optionvalues[el])) {
          initial = {}
        }
        const newproperty = []
        newproperty[el] = initial
        this.optionvalues = Object.assign({}, this.optionvalues, newproperty)
      })
      this.$v.$reset()
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title'), class: 'text-left' },
        { key: 'description', label: this.$t('common.description'), class: 'text-left' },
        { key: 'action', label: this.$t('common.actions'), class: 'text-left' }
      ]
    }
  },
  watch: {
    sections: {
      handler: function (value) {
        value.forEach((el) => {
          const propertyName = el.model.toString().split(' ').join('').toLowerCase()
          const optionNew = {}
          optionNew[propertyName] = el.value
          this.optionvalues = Object.assign({}, this.optionvalues, optionNew)
        })
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/custom-tab-filter.scss'
</style>
