import axios from 'axios'

export default {
  data () {
    return {
      groups: ['asset', 'liability'],
      allSettings: [],
      busySettingsState: true,
      preSelectId: null,
      preFetchPathsSettings: ['over-time-report', 'asset-allocation', 'over-time-report', 'four-buckets', 'balance-report', 'asset-types-report']
    }
  },
  methods: {
    fetchAccountGroups (AccountType) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=TabSettingsCommon`)
    },
    settingsActions (param, method) {
      const url = `${process.env.VUE_APP_ROOT_API}/tab/settings/${param}`
      return axios({
        url,
        method
      })
    },
    fetchAllSettings (filter = null) {
      let url = `${process.env.VUE_APP_ROOT_API}/tab/settings`
      if (filter) {
        url += `?filter=${filter}`
      }
      return axios({
        url,
        method: 'GET'
      })
    },
    initializing (cb) {
      this.fetchAccountGroups(this.groups.join(','))
        .then((response) => response.data.data)
        .then((response) => {
          const sectionsArray = [...this.sections]
          this.groups.map((elements) => {
            const propertyValue = response.filter((el) => el.type === elements).map((el) => {
              const accounts = el.accounts.map((elem) => {
                return { text: elem.title, value: elem.id }
              })
              return { label: el.title, options: accounts }
            })
            const findIndex = sectionsArray.findIndex((el) => el.model === elements)
            if (findIndex > -1) {
              sectionsArray[findIndex].child[0] = Object.assign({}, sectionsArray[findIndex].child[0], { options: propertyValue })
            }
          })
          /*
          * Emit section update
          */
          this.$emit('sections-update', sectionsArray)
        })
        .finally(() => {
          this.refreshAllSettings()
          if (typeof cb === 'function') {
            cb()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    refreshAllSettings () {
      this.busySettingsState = true
      const filtered = this.preFetchPathsSettings && Array.isArray(this.preFetchPathsSettings) && this.preFetchPathsSettings.length > 0 ? this.preFetchPathsSettings.join(',') : this.$router.history.current.name
      this.fetchAllSettings(filtered)
        .then((response) => response.data.data)
        .then((res) => {
          const { settings } = res
          if (settings) {
            this.allSettings = settings
          }
        })
        .finally(() => {
          this.busySettingsState = false
        })
        .catch((e) => {
          console.log(e)
        })
    },
    handleActiveTabs (value) {
      if (!value) return false
      const updatedValue = []
      Object.keys(value).map((el) => {
        const findIndexeGroup = this.sections.findIndex((ele) => ele.model === el)
        updatedValue.push(Object.assign({}, this.sections[findIndexeGroup], { value: value[el] }))
      })
      this.$emit('sections-update', updatedValue)
      let url = `${process.env.VUE_APP_ROOT_API}/tab/settings`
      let method = 'POST'
      if (this.preSelectId) {
        url += `/${this.preSelectId}`
        method = 'PUT'
      }
      axios({
        url,
        method,
        data: {
          path: this.$router.history.current.name,
          title: Object.prototype.hasOwnProperty.call(value, 'title') && value.title ? value.title : null,
          description: Object.prototype.hasOwnProperty.call(value, 'description') && value.description ? value.description : null,
          content: value
        }
      }).then(() => {
        this.$bvToast.toast(this.$t('common.settings_save_success'), {
          title: this.$t('settings.submenu.settings_title'),
          variant: 'success',
          solid: true
        })
        this.refreshAllSettings()
        this.afterTabSettingsUpdate()
      }).catch(() => {
        this.$bvToast.toast(this.$t('common.settings_save_failed'), {
          title: this.$t('settings.submenu.settings_title'),
          variant: 'danger',
          solid: true
        })
      })
    },
    handleDelete (id) {
      this.settingsActions(id, 'DELETE')
        .then(() => {
          this.afterTabSettingsDelete()
          this.$bvToast.toast(this.$t('common.settings_delete_success'), {
            title: this.$t('settings.submenu.settings_title'),
            variant: 'success',
            solid: true
          })
          this.refreshAllSettings()
        }).catch(() => {
          this.$bvToast.toast(this.$t('common.settings_delete_failed'), {
            title: this.$t('settings.submenu.settings_title'),
            variant: 'danger',
            solid: true
          })
        })
    },
    afterTabSettingsUpdate () {
      return true
    },
    afterTabSettingsDelete () {
      return true
    }
  }
}
