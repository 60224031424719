<template>
  <div class="component-common-empty-graph" style="height:250px;">
    <b-alert variant="warning" class="mx-auto" style="max-width:1440px;" show>
      <p class="col-lg-8 col-md-10 font-weight-medium text-center mx-auto" style="font-size:16px">
        {{ message }}
      </p>
    </b-alert>
  </div>
</template>

<script>

export default {
  name: 'EmptyGraph',
  props: {
    message: {
      type: String,
      default: function () {
        return this.$t('common.errors.no_graph_data')
      }
    }
  }
}
</script>
