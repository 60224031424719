<template>
  <b-container fluid class="balance-report-chart-liabilities-chart-wrapper">
    <template v-if="apiData && !Array.isArray(this.apiData)&& Object.keys(apiData).length > 0">
      <bar-chart
        :chart-data="chartData"
        :options="chartOptions"
        :chart-id="'balance-report-chart-liabilities-chart'"
        :width="100"
        :height="400"
        :css-classes="''"
        :plugins="[]"
      >
      </bar-chart>
    </template>
    <template v-else>
      <empty-graph :message="$t('incomes_expenses.accounts.view.no_transaction_no_chart')"/>
    </template>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import EmptyGraph from '@/components/common/EmptyGraph'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceReportChartLiabilities',
  props: [
    'apiData',
    'translationPath',
    'currencySymbol'
  ],
  mixins: [chartOptions],
  components: {
    BarChart,
    EmptyGraph
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: []
      }
    }
  },
  mounted () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      if (!this.apiData || this.apiData == null || Array.isArray(this.apiData) || Object.keys(this.apiData).length === 0) {
        return 0
      }
      // Custom chart options
      this.chartOptions.legend.display = false
      this.chartOptions.title.display = true
      this.chartOptions.title.text = '(*) ' + this.$t(this.translationPath + 'chart_label_numbers_per_date', { end_date: this.apiData.dates.closing_balance })
      this.chartOptions.title.position = 'bottom'
      this.chartOptions.title.fontStyle = 'normal'

      const suffix = ' ' + this.currencySymbol

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      this.chartOptions.scales.xAxes[0].ticks.callback = function (value, index, values) {
        return value
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        const tooltipLabel = ''

        /*
        switch (tooltipItem.datasetIndex) {
          case 0 :
            tooltipLabel = cLabelIncomes
            break
          case 1 :
            tooltipLabel = cLabelExpenses
            break
        }
        */
        return tooltipLabel + ' ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const labelData = []
      const data = []

      for (var index in this.apiData.liability) {
        labelData.push(this.apiData.liability[index].title)
        data.push(Math.round(this.apiData.liability[index].sums.closing_balance))
      }

      const dataset = {
        data: data,
        backgroundColor: '#dda3a2'
      }

      this.chartData = {
        labels: labelData,
        datasets: [dataset]
      }
    }
  }
}
</script>

<style lang="scss">
  .balance-report-chart-net-worth-chart{

  }
</style>
