<template>
  <b-container fluid class="balance-report-table-liabilities-wrapper px-0">

    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t(translationPath + 'title_liabilities_grouped' )}}</h2>
          </template>

          <p class="col-lg-10 pl-0">{{ $t(translationPath + 'description_liabilities_groups')}} </p>

          <b-table class="spirecta-simple-table balance-report-table-liabilities" show-empty hover responsive striped
                   :items="items"
                   :fields="tableFields"
                   stacked="md"
          >
            <template v-slot:head(opening_balance)="item">
              {{item.label}}<br />
              <small>{{ opening_balance_date }}</small>
            </template>

            <template v-slot:head(closing_balance)="item">
              {{item.label}}<br />
              <small>{{ closing_balance_date }}</small>
            </template>

            <template v-slot:head(period_change)="item">
              {{item.label}}<br />
              <small>{{ period_start_date }} - {{ period_end_date }}</small>
            </template>

            <template v-slot:cell(type)="item">
              <b-link class="group_link" :href="'#account'+item.item.id">{{item.value}}</b-link>
            </template>
          </b-table>

        </b-card>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mt-4">
          <template v-slot:header>
            <h2>{{ $t(translationPath + 'title_liabilities' )}}</h2>
          </template>

          <p class="col-lg-10 pl-0">{{ $t(translationPath + 'description_liabilities')}} </p>

          <b-table class="spirecta-simple-table balance-report-table-liabilities-detailed mb-0" show-empty hover responsive striped
                   :items="itemsDetailed"
                   :fields="tableFields"
                   :tbodyTrClass="customizeRow"
                   stacked="md"
                   thead-class="hidden_header"
                   :busy="busyState"
          >

            <template v-slot:cell(type)="item">
              <template v-if="item.item.row_type === 'title_row'">
                <h2><a :name="'account'+item.item.id"></a><b-link class="text-black font-weight-medium mb-0" :to="'/reports/performance/account-groups/'+item.item.id">{{ item.value }}</b-link></h2>
              </template>
              <template v-else-if="item.item.row_type === 'account_group'">
                {{ $t('common.subtotal') }} <small class="text-secondary">({{item.value}})</small>
              </template>
              <template v-else-if="item.item.row_type === 'account_row'">
                <b-link class="font-weight-normal" :to="'/reports/performance/accounts/'+item.item.id+'/view'">{{ item.value }}</b-link>
              </template>
              <template v-else-if="item.item.row_type === 'empty_row'">
                &nbsp;
              </template>
              <template v-else-if="item.item.row_type === 'total'">
                {{$t('common.sum_incomes')}}
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </template>

            <template v-slot:cell(opening_balance)="item">
              <template v-if="item.item.row_type === 'title_row'">
                <span class="text-secondary">
                  {{ $t(translationPath + 'table.opening_balance') }}<br />
                  <small>{{ opening_balance_date }}</small>
                </span>
              </template>
              <template v-else-if="item.item.row_type === 'empty_row'">
                &nbsp;
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </template>

            <template v-slot:cell(period_change)="item">
              <template v-if="item.item.row_type === 'title_row'">
                <span class="text-secondary">
                  {{ $t(translationPath + 'table.period_change') }}<br />
                  <small>{{ period_start_date }} - {{ period_end_date }}</small>
                </span>
              </template>
              <template v-else-if="item.item.row_type === 'empty_row'">
                &nbsp;
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </template>

            <template v-slot:cell(closing_balance)="item">
              <template v-if="item.item.row_type === 'title_row'">
                <span class="text-secondary">
                  {{ $t(translationPath + 'table.closing_balance') }}<br />
                  <small>{{ closing_balance_date }}</small>
                </span>
              </template>
              <template v-else-if="item.item.row_type === 'empty_row'">
                &nbsp;
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </template>

          </b-table>
          <b-link href="#" @click.prevent="exportToCsv"  class="text-red">{{$t('common.export_to_csv' )}} →</b-link>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import formatAmount from '@/assets/filters/formatAmount'
import defaultValueAssigner from '@/mixins/defaultValueAssigner'

export default {
  props: {
    translationPath: null,
    apiData: null,
    busyState: null,
    currentCOA: null
  },
  name: 'BalanceReportTableLiabilities',
  mixins: [defaultValueAssigner],
  data () {
    return {
      items: [],
      itemsDetailed: [],
      opening_balance_date: null,
      period_start_date: null,
      period_end_date: null,
      closing_balance_date: null
    }
  },
  computed: {
    tableFields () {
      return [
        { key: 'type', label: this.$t(this.translationPath + 'table.title_liability_groups'), class: 'table_header' },
        { key: 'opening_balance', label: this.$t(this.translationPath + 'table.opening_balance'), class: 'text-left text-md-right', formatter: 'amountFormatter' },
        { key: 'period_change', label: this.$t(this.translationPath + 'table.period_change'), class: 'text-left text-md-right', formatter: 'amountFormatter' },
        { key: 'closing_balance', label: this.$t(this.translationPath + 'table.closing_balance'), class: 'text-left text-md-right', formatter: 'amountFormatter' }
      ]
    }
  },
  created () {
    this.loadTableData()
  },
  methods: {
    amountFormatter (value) {
      return this.currentCOA && this.currentCOA.locale && this.currentCOA.currency && this.currentCOA.currency_iso ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : value
    },
    loadTableData () {
      if (this.busyState === false) {
        this.items = []
        this.itemsDetailed = []
        if (!this.apiData || Array.isArray(this.apiData) || Object.keys(this.apiData).length === 0) {
          return false
        }
        this.opening_balance_date = this.apiData.dates.opening_balance
        this.closing_balance_date = this.apiData.dates.closing_balance
        this.period_start_date = this.apiData.dates.period_start_date
        this.period_end_date = this.apiData.dates.period_end_date

        // Sort account groups directly
        // eslint-disable-next-line vue/no-mutating-props
        this.apiData.liability = Object.values(this.apiData.liability).sort((a, b) => { // TODO: @Varun
          const nameA = a.title
          const nameB = b.title
          return nameA.localeCompare(nameB, this.local)
        })

        for (const iCnt in this.apiData.liability) {
          // Sort accounts directly
          // eslint-disable-next-line vue/no-mutating-props
          this.apiData.liability[iCnt].accounts = Object.values(this.apiData.liability[iCnt].accounts).sort((a, b) => { // TODO: @Varun
            const nameA = a.title
            const nameB = b.title
            return nameA.localeCompare(nameB, this.local)
          })

          /* Grouped liabilities */
          this.items.push({
            type: this.apiData.liability[iCnt].title,
            id: this.apiData.liability[iCnt].id,
            opening_balance: this.apiData.liability[iCnt].sums.opening_balance,
            period_change: this.apiData.liability[iCnt].sums.period_change,
            closing_balance: this.apiData.liability[iCnt].sums.closing_balance
          })

          /* Detailed liabilities */
          this.itemsDetailed.push({ row_type: 'title_row', id: this.apiData.liability[iCnt].id, type: this.apiData.liability[iCnt].title, opening_balance: '', period_change: '', closing_balance: '' })

          for (const iCntAccount in this.apiData.liability[iCnt].accounts) {
            this.itemsDetailed.push({
              row_type: 'account_row',
              id: this.apiData.liability[iCnt].accounts[iCntAccount].id,
              type: this.apiData.liability[iCnt].accounts[iCntAccount].title,
              opening_balance: this.apiData.liability[iCnt].accounts[iCntAccount].opening_balance,
              period_change: this.apiData.liability[iCnt].accounts[iCntAccount].period_change,
              closing_balance: this.apiData.liability[iCnt].accounts[iCntAccount].closing_balance
            })
          }

          this.itemsDetailed.push({
            row_type: 'group_total',
            type: this.$t('common.subtotal'),
            opening_balance: this.apiData.liability[iCnt].sums.opening_balance,
            period_change: this.apiData.liability[iCnt].sums.period_change,
            closing_balance: this.apiData.liability[iCnt].sums.closing_balance
          })

          this.itemsDetailed.push({
            row_type: 'empty_row',
            type: '',
            opening_balance: '',
            period_change: '',
            closing_balance: ''
          })
        }

        this.items.push({
          type: this.$t(this.translationPath + 'table.total_liabilities'),
          opening_balance: this.apiData.totals.sums.liability.opening_balance,
          period_change: this.apiData.totals.sums.liability.period_change,
          closing_balance: this.apiData.totals.sums.liability.closing_balance
        })

        this.itemsDetailed.push({
          row_type: 'grand_total',
          type: this.$t(this.translationPath + 'table.total_liabilities'),
          opening_balance: this.apiData.totals.sums.liability.opening_balance,
          period_change: this.apiData.totals.sums.liability.period_change,
          closing_balance: this.apiData.totals.sums.liability.closing_balance
        })
      }
    },
    customizeRow (item, type) {
      let rowClass = ''
      if (item) {
        if (rowClass !== '' && item.row_type) {
          rowClass += ' ' + item.row_type
        }
        if (rowClass === '' && item.row_type) {
          rowClass += item.row_type
        }
      }
      return rowClass
    },
    exportToCsv () {
      let data = this.$t(this.translationPath + 'export_csv_title') + '\n\n'
      data += this.$t(this.translationPath + 'export_csv_period', { from: this.period_start_date, to: this.period_end_date }) + '\n\n'
      data += this.$t(this.translationPath + 'export_csv_heading') + '\n'

      this.itemsDetailed.map(row => {
        switch (row.row_type) {
          case 'title_row':
            data += '\n' + row.type + '\n'
            break
          case 'account_row':
          case 'group_total':
            data += row.type + ';' + parseInt(row.opening_balance) + ';' + parseInt(row.period_change) + ';'
            data += parseInt(row.closing_balance) + '\n'
            break
          case 'grand_total':
            data += '\n' + row.type + ';' + parseInt(row.opening_balance) + ';' + parseInt(row.period_change) + ';'
            data += parseInt(row.closing_balance) + '\n'
            break
        }
      })

      const url = window.URL.createObjectURL(new Blob(['\ufeff', data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-liabilities-' + (new Date().toISOString().split('T')[0]) + '.csv')
      document.body.appendChild(link)
      link.click()
    }
  },
  watch: {
    apiData: {
      handler () {
        this.loadTableData()
      }
    },
    busyState: {
      handler () {
        this.loadTableData()
      }
    }
  }
}
</script>

<style type="text/css" lang="scss">
  .balance-report-table-liabilities {
    .group_link {
      font-weight: 400;
      color:black;
    }

    thead th:first-child{
      font-size:24px;
    }

    tr:last-child {
      border-top:1px solid black;
      border-bottom:2px solid black;
      font-weight: 900;
    }

    tr:last-child td a{
      font-weight: 900;
    }
  }

  .balance-report-table-liabilities-detailed {
    .hidden_header{display: none}

    .title_row td{
      background:white;
      border-left-width:0!important;
      border-top:0;
    }

    .group_total td{
      border-top:1px solid #ACAEBA;
      border-bottom:2px solid #ACAEBA;
      font-weight: 900;
    }

    .grand_total td{
      border-top:1px solid black;
      border-bottom:2px solid black;
      font-weight: 900;
    }

    .account_row td a{
      color:black;
    }

    .empty_row td{
      background:white;
      border-left-width:0!important;
    }
  }
</style>
