<template>
  <div class="reports-balance-report">

    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="7" md="8" lg="9" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title_report') }}</h1>
            </b-col>
            <b-col cols="12" sm="5" md="4" lg="3" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                :show-predefined-periods="true"
                :show-resolution="false"
                default-period-type="year-to-date"
                default-resolution="month"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-4" no-gutters :class="{ 'pb-5': activeTab === 'settings'}">
          <b-col no-gutters>
            <balance-report-tabs ref="Tabmenu" :translationPath="translationPath" @tab-switched="onTabSwitched" :active="activeTab"></balance-report-tabs>
          </b-col>
        </b-row>
      </b-container>

      <b-row class="main-gradient-chart-wrapper" :class="{ 'd-none': activeTab === 'settings'}">
        <div v-if="currentCOA.currency" class="pb-5" style="width:100%;height:500px;">
          <balance-report-chart-net-worth :translationPath="translationPath" v-if="activeTab === 'networth'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" :currencySymbol="currencySymbol"></balance-report-chart-net-worth>
          <balance-report-chart-change-distribution :translationPath="translationPath" v-if="activeTab === 'change_distribution'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" :currencySymbol="currencySymbol"></balance-report-chart-change-distribution>
          <balance-report-chart-time-weighted-return :translationPath="translationPath" v-if="activeTab === 'time_weighted_return'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA"></balance-report-chart-time-weighted-return>
          <balance-report-chart-assets :translationPath="translationPath" v-if="activeTab === 'asset'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" :currencySymbol="currencySymbol"></balance-report-chart-assets>
          <balance-report-chart-assets-detailed :translationPath="translationPath" v-if="activeTab === 'asset-detailed'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" :currencySymbol="currencySymbol"></balance-report-chart-assets-detailed>
          <balance-report-chart-liabilities :translationPath="translationPath" v-if="activeTab === 'liability'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" :currencySymbol="currencySymbol"></balance-report-chart-liabilities>
          <balance-report-chart-liabilities-detailed :translationPath="translationPath" v-if="activeTab === 'liability-detailed'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" :currencySymbol="currencySymbol"></balance-report-chart-liabilities-detailed>
        </div>
      </b-row>

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0" :class="{ 'pb-0': activeTab === 'settings'}">
      <balance-report-table-change-distribution :translationPath="translationPath" v-if="activeTab === 'change_distribution' || activeTab === 'time_weighted_return'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" :periodStart="period.start" :periodEnd="period.end"></balance-report-table-change-distribution>
      <balance-report-table-net-worth :translationPath="translationPath" v-if="activeTab === 'networth' || activeTab === 'change_distribution' || activeTab === 'time_weighted_return'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA" @change-tab="onTabSwitched"></balance-report-table-net-worth>
      <balance-report-table-assets :translationPath="translationPath" v-if="activeTab === 'asset' || activeTab === 'asset-detailed'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA"></balance-report-table-assets>
      <balance-report-table-liabilities :translationPath="translationPath" v-if="activeTab === 'liability' || activeTab === 'liability-detailed'" :apiData="apiData" :busyState="busyState" :currentCOA="currentCOA"></balance-report-table-liabilities>
      <balance-report-settings v-if="activeTab === 'settings'" :sections="sections" @sections-update="handleSectionUpdate" @settings-updated="handleSettingsUpdate" />
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import axios from 'axios'
import PeriodSelector from '@/components/common/PeriodSelector'
import BalanceReportTabs from './BalanceReportTabs'
import BalanceReportTableChangeDistribution from './BalanceReportTableChangeDistribution'
import BalanceReportTableAssets from './BalanceReportTableAssets'
import BalanceReportTableLiabilities from './BalanceReportTableLiabilities'
import BalanceReportTableNetWorth from './BalanceReportTableNetWorth'

import BalanceReportChartNetWorth from './BalanceReportChartNetWorth'
import BalanceReportChartChangeDistribution from './BalanceReportChartChangeDistribution'
import BalanceReportChartAssets from './BalanceReportChartAssets'
import BalanceReportChartAssetsDetailed from './BalanceReportChartAssetsDetailed'
import BalanceReportChartLiabilities from './BalanceReportChartLiabilities'
import BalanceReportChartLiabilitiesDetailed from './BalanceReportChartLiabilitiesDetailed'
import BalanceReportChartTimeWeightedReturn from './BalanceReportChartTimeWeightedReturn'
import BalanceReportSettings from './BalanceReportSettings'

import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import _ from 'lodash'

export default {
  name: 'Balance2',
  mixins: [titleMixins],
  components: {
    BalanceReportTableNetWorth,
    BalanceReportTabs,
    BalanceReportTableAssets,
    BalanceReportTableLiabilities,
    BalanceReportTableChangeDistribution,
    BalanceReportChartNetWorth,
    BalanceReportChartAssets,
    BalanceReportChartChangeDistribution,
    BalanceReportChartTimeWeightedReturn,
    BalanceReportChartAssetsDetailed,
    BalanceReportChartLiabilities,
    BalanceReportChartLiabilitiesDetailed,
    PeriodSelector,
    BalanceReportSettings
  },
  data () {
    return {
      busyState: true,
      showToastOnYearChange: false,
      items: {
        asset: null,
        liability: null,
        result: null
      },
      filter: {
        asset: null,
        liability: null
      },
      period: {
        start: new Date(new Date().getFullYear() + '-01-01').toISOString().slice(0, 10),
        end: new Date().toISOString().slice(0, 10)
      },
      activeTab: 'networth',
      apiData: null,
      translationPath: 'reports.balance.balance_report2.',
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      sections: [
        {
          label: 'common.assets',
          description: 'common.settings.descriptions.asset',
          model: 'asset',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.liabilities',
          description: 'common.settings.descriptions.liability',
          model: 'liability',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.title',
          model: 'title',
          value: null,
          required: true,
          disableButtons: true,
          child: [
            {
              type: 'input'
            }
          ]
        },
        {
          label: 'common.advanced_filter.description',
          model: 'description',
          value: null,
          show: true,
          disableButtons: true,
          child: [
            {
              type: 'textarea'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    titleMeta () {
      return this.$t(this.translationPath + 'title_report')
    },
    local () {
      return this.currentUser && Object.prototype.hasOwnProperty.call(this.currentUser, 'default_lang') && this.currentUser.default_lang ? this.currentUser.default_lang : 'en'
    },
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance' },
        { text: '', active: true }
      ]
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    disabledStartDates () {
      if (this.period.end) {
        const endDate = new Date(this.period.end)
        endDate.setDate(endDate.getDate() - 1)
        return { from: endDate }
      }
      return { from: null }
    },
    disabledEndDates () {
      if (this.period.start) {
        const startDate = new Date(this.period.start)
        startDate.setDate(startDate.getDate() + 1)
        return { to: startDate }
      }
      return { to: null }
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    handleSectionUpdate (value) {
      this.sections = value
    },
    handleSettingsUpdate (value) {
      this.handleSectionUpdate(value)
      this.loadingDataToast()
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description'), {
            title: this.$t('common.filter_settings_title'),
            variant: 'success',
            solid: true
          })
        })
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: newtab } })
      }
    },
    changeTab (tab) {
      if (['networth', 'asset', 'change_distribution', 'asset-detailed', 'liability', 'liability-detailed', 'settings', 'time_weighted_return'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
        this.setPageTitle(this.$t(this.translationPath + 'title_report'))
      }
    },
    onPeriodChange (eventData) {
      this.period.start = eventData.sDateStart
      this.period.end = eventData.sDateEnd
    },
    async loadData () {
      if (this.currentCOA.locale === null) {
        return false
      }
      this.busyState = true
      const sectionsValue = _.flatten([...this.sections].filter((el) => el.model !== 'title' && el.model !== 'description').map((el) => el.value).filter((el) => el))
      const include = sectionsValue.length > 0 ? sectionsValue.join(',') : null
      let ApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balance2?startDate=${this.dateFormatter(this.period.start)}&endDate=${this.dateFormatter(this.period.end)}&locale=${this.currentCOA.locale}`
      if (include && include.length > 0) {
        ApiEndpoint += `&include=${include}`
      }
      await axios.get(ApiEndpoint)
        .then((response) => {
          this.apiData = response.data.data
          this.showToastOnYearChange = true
        })
        .catch((error) => {
          console.error(error)
        })
      this.busyState = false
    },
    dateFormatter (date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
    loadingDataToast () {
      this.$bvToast.toast(this.$t('common.toasts.loading_data'), {
        title: this.$t(this.translationPath + 'title_report'),
        variant: 'info',
        solid: true
      })
    }
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
    this.loadingDataToast()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    activeTab: function (newVal, oldVal) {
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    period: {
      deep: true,
      handler (newVal, oldVal) {
        if (this.showToastOnYearChange) {
          this.$bvToast.toast(this.$t('common.toasts.loading_data_for_period', { start_date: this.period.start, end_date: this.period.end }), {
            title: this.$t(this.translationPath + 'title_report'),
            variant: 'info',
            solid: true
          })
        } else {
          this.showToastOnYearChange = true
        }

        if (newVal) {
          this.loadData().then(status => {
            this.$bvToast.toast(this.$t('common.toasts.data_for_period_is_now_loaded', { start_date: this.start_date, end_date: this.end_date }), {
              title: this.$t(this.translationPath + 'title_report'),
              variant: 'success',
              solid: true
            })
          })
        }
      }
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  }
}
</script>

<style lang="scss">
.reports-balance-report
{
  width: 100%;
}

.report-balance2 {
  thead {
    display: none;
  }
  a {
    color: #000;
  }
  .whiteBackground {
    background: #fff;
  }
  tr.group {
    background-color: #fff !important;
  }
  tr.group td {
    border-top: none;
    border-bottom: 1px solid #000;
    padding-top: 12px;
  }
  tr.empty_row {
    background-color: #fff !important;
  }
  tr.type_result_row {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  tr.total_result {
    border-top: 1px solid #000;
    border-bottom: 2px solid #000;
  }
  .account_title {
    font-weight: normal;
  }
  .table-title {
    font-size: 24px;
    line-height: 32px;
    color: #434343;
  }
  .table-title-label {
    width: 24px;
    height: 24px;
    border: 3px solid #D8D8D8;
    border-radius: 20px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 10px;
  }
  .table-title-label-icon {
    position: relative;
    left: -27px;
    top: -2px;
    font-size: 18px;
    font-weight: 700;
  }
  .table-title-help {
    color: #0065ff;
  }
  .group_result_row {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    .row-title {
      font-style: italic;
      font-weight: 400;
    }
  }
  .balance2_form {
    padding: 20px 20px 15px 20px;
    background-color: #fff;
  }
}
</style>
