<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="networth" :class="{selected: activeTab === 'networth'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_networth') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="change_distribution" :class="{selected: activeTab === 'change_distribution'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_change_distribution') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="time_weighted_return" :class="{selected: activeTab === 'time_weighted_return'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_time_weighted_return') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="asset" :class="{selected: activeTab === 'asset'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_assets') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="asset-detailed" :class="{selected: activeTab === 'asset-detailed'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_assets_detailed') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liability" :class="{selected: activeTab === 'liability'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_liabilities') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liability-detailed" :class="{selected: activeTab === 'liability-detailed'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_liabilities_detailed') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="settings" :class="{selected: activeTab === 'settings'}"  @click="onTabClick">
          {{ $t('common.header.header_menu.user_menu.settings') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    translationPath: {},
    active: {
      type: String,
      default: 'networth'
    }
  },
  name: 'BalanceReportTabs',
  data () {
    return {
      activeTab: null
    }
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  },
  watch: {
    active: {
      handler () {
        if (this.active) {
          this.activeTab = this.active
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
